import * as React from "react"
import queryString from "querystring"
import config from "../../../config"
import { getMobilePayments } from "../../../api/content"
import CheckoutForm from "../../../components/forms/payments/checkoutForm"
import PaymentMethodForm from "../../../components/forms/payments/paymentMethods"
import NewCardForm from "../../../components/forms/payments/newCard"

const profileUri = config.serverUri

const MobilePayments = ({ location, optionPaymentForm }) => {
  const [error, setError] = React.useState("")
  const [paymentForm, setPaymentForm] = React.useState("checkout")
  const [loading, setLoading] = React.useState(true)
  const [paymentInfo, setPaymentInfo] = React.useState({
    paymentMethods: [],
  })
  const [token, setToken] = React.useState("")
  const [unSuscribeForm, setUnSuscribeForm] = React.useState(false)
  const [ispwa, setIspwa] = React.useState(null)

  const [reference, setReference] = React.useState()
  const [code, setCode] = React.useState()

  const [isSocioPremium, setIsSocioPremium] = React.useState(null)
  const [membershipByCode, setMembershipByCode] = React.useState()

  React.useEffect(() => {
    if (optionPaymentForm === "newCard") {
      setPaymentForm("newCard")
    }
    const qs = location.search
    const queries = qs.split("?")

    let authorization = undefined
    let refQs = undefined

    if (queries.length > 1) {
      queries[1].split("&").forEach(query => {
        if (query.includes("authorization")) {
          authorization = query.split("=")[1]
          setIspwa(authorization)
        }
        if (query.includes("ref")) {
          refQs = query.split("=")[1]
          setReference(query.split("=")[1])
        }
        if (query.includes("code")) {
          setCode(query.split("=")[1])
        }
      })

      if (authorization) {
        getMobilePayments(authorization)
          .then(result => {
            setToken(authorization)
            setPaymentInfo(result)
            setLoading(false)
            if (refQs) {
              fetch(`${profileUri}/suscripcions/?id=${refQs}`)
                .then(response => response.json())
                .then(data => {
                  console.log(data[0])
                  setMembershipByCode(data[0])
                })
                .catch(error => {
                  console.log(error)
                })
            }
            fetch(`${profileUri}/users-suscriptions/?userId=${result.id}`)
              .then(response => response.json())
              .then(data => {
                data[0] && data[0].suscriptionId === "shift-socios"
                  ? setIsSocioPremium(true)
                  : setIsSocioPremium(false)
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
            setError(
              "No se ha podido cargar tu perfil de pagos intenta nuevamente."
            )
            setLoading(false)
          })
      } else {
        console.log("Error")
        setError(
          "No tienes permiso para ver esta página. No se encontró información de autorización en la solicitud"
        )
        setLoading(false)
      }
    }
  }, [])

  const getMessage = code => {
    if (code === "auth/invalid-custom-token")
      return "No tienes permiso para ver esta página. Información de autorización incorrecta"
    return code
  }

  const handleCardAdded = card => {
    setPaymentInfo({
      ...paymentInfo,
      paymentMethods: [card, ...paymentInfo.paymentMethods],
    })
    setPaymentForm("paymentMethods")
  }

  const handleMethodChange = card => {
    setPaymentInfo({ ...paymentInfo, defaultMethod: card })
    setPaymentForm("checkout")
  }

  const handleMethodDelete = card => {
    const filteredMethods = paymentInfo.paymentMethods.filter(
      item => item.id !== card.id
    )
    setPaymentInfo({ ...paymentInfo, paymentMethods: filteredMethods })
  }

  return (
    <div className="p-8">
      {!loading ? (
        <div>
          {!error ? (
            <div>
              {paymentForm === "checkout" && (
                <CheckoutForm
                  membershipByCode={membershipByCode}
                  code={code}
                  reference={reference}
                  location={location}
                  setUnSuscribeForm={setUnSuscribeForm}
                  onEditMethodRequest={() => {
                    setPaymentForm("paymentMethods")
                  }}
                  token={token}
                  onSuccessSuscription={suscription => {
                    setPaymentInfo({ ...paymentInfo, suscribed: true })
                  }}
                  onCancelSuscription={suscription => {
                    setPaymentInfo({ ...paymentInfo, suscribed: false })
                  }}
                  paymentInfo={paymentInfo}
                  isSocioPremium={isSocioPremium}
                />
              )}
              {paymentForm === "paymentMethods" && (
                <PaymentMethodForm
                  token={token}
                  paymentMethods={paymentInfo.paymentMethods}
                  onDeleteMethod={handleMethodDelete}
                  selectedMethod={paymentInfo.defaultMethod}
                  goBack={() => {
                    setPaymentForm("checkout")
                  }}
                  onChangeMethod={handleMethodChange}
                  onAddCardRequest={() => {
                    setPaymentForm("newCard")
                  }}
                  pwa={ispwa}
                />
              )}
              {paymentForm === "newCard" && (
                <NewCardForm
                  location={location}
                  goBack={() => {
                    setPaymentForm("paymentMethods")
                  }}
                  onCardAdded={handleCardAdded}
                  token={token}
                  isPwa={ispwa}
                />
              )}
            </div>
          ) : (
            <div>
              <p>{error}</p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default MobilePayments
